import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getToken } from ".";
import { refreshToken } from "./auth";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
  timeout: 20000,
});

// Add a request interceptor
instance.interceptors.request.use((config) => {
  const token = getToken();
  if (token && !config.url.includes("oauth")) {
    config.headers.Authorization = `Bearer ${token.access_token}`;
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    response.ok = true;
    if (response.config.headers.noMessage) {
      return response;
    } else if (response.config.headers.MensagemPersonalizada) {
      toastr.success("Sucesso", response.config.headers.MensagemPersonalizada);
    } else if (
      response.status === 202 &&
      (response.config.method === "put" || response.config.method === "patch")
    ) {
      toastr.success("Sucesso", "Registro Atualizado com sucesso.");
    } else if (response.status === 201 && response.config.method === "post") {
      toastr.success("Sucesso", "Registro Adicionado com sucesso.");
    } else if (response.status === 204 && response.config.method === "delete") {
      toastr.success("Sucesso", "Registro Excluído com sucesso.");
    }
    // Do something with response data
    return response;
  },
  (error) => {
    error.ok = false;
    if (error.config && error.config.url.includes("/token")) {
      return error;
    }
    // You can even test for a response code
    // and try a new request before rejecting the promise
    if (error.response && error.response.status === 401) {
      return refreshToken().then((token) => {
        if (token && token.access_token) {
          return instance(error.config);
          //return api.request(response.config);
        } else {
          toastr.warning("Token expirado, favor, se autentique novamente.");
          setTimeout(() => {
            window.location.href = `${window.location.origin}/pages/login`;
          }, 2000);
          return error;
        }
      });
    } else if (error.response && error.response.status === 404) {
      toastr.info("Dado não encontrado!");
    } else {
      toastr.error("Erro ao efetuar a requisição!");
    }
    return error;
  },
);

export default instance;
