import { axios, setObject } from ".";
import { clear } from "../services/store";

const TOKEN_KEY = "@HSGJ%$DF$#DDSH6sagewkas";
const TOKEN_BASIC = "c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1sZXhwOnNlY3JldExleHA=";
const setToken = (token) =>
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
const delToken = () => localStorage.removeItem(TOKEN_KEY);

export const isAuthenticated = () => getToken() !== null;
export const getToken = () => {
  const getTokenData = localStorage.getItem(TOKEN_KEY);
  const getTokenParsed = getTokenData ? JSON.parse(getTokenData) : null;
  return getTokenParsed;
};

export const login = async (username, password) => {
  var bodyFormData = new URLSearchParams();
  bodyFormData.append("username", username);
  bodyFormData.append("password", password);
  bodyFormData.append("grant_type", "password");

  const { data: token, ok } = await axios.post("/oauth/token", bodyFormData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${TOKEN_BASIC}`,
    },
  });

  if (ok) {
    // axios.defaults.headers[
    //   "Authorization"
    // ] = `Bearer ${responseToken.data.access_token}`;

    setToken(token);

    const { data: user } = await axios.get("/user/principal", {
      headers: { Authorization: `Bearer ${token.access_token}` },
    });
    setObject("user", user);

    return user;
  }
  delToken();
  return null;
};

export const refreshToken = async () => {
  const token = getToken();
  if (!token) return null;
  var bodyFormData = new URLSearchParams();
  bodyFormData.append("refresh_token", token.refresh_token);
  bodyFormData.append("grant_type", "refresh_token");

  const responseToken = await axios.post("/oauth/token", bodyFormData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token.refresh_token}`,
    },
  });

  // if (responseToken && responseToken.status === 200) {
  //   axios.defaults.headers[
  //     "Authorization"
  //   ] = `Bearer ${responseToken.data.access_token}`;
  // }
  if (responseToken.ok) {
    setToken(responseToken.data);
  } else {
    delToken();
  }

  return responseToken.data;
};
export const logout = () => {
  clear();
};
