import * as types from "../../types/menu";

const initialState = {
  menuViews: [],
  tooltipTicket: {},
  tooltipPlano: {},
  tooltipPesquisa: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ATUALIZA_MENU_VIEWS:
      return { ...state, erro: "", menuViews: payload };

    case types.ATUALIZA_MENU_TOLLTIPS_TICKET:
      return { ...state, erro: "", tooltipTicket: payload };

    case types.ATUALIZA_MENU_TOLLTIPS_PLANO:
      return { ...state, erro: "", tooltipPlano: payload };

    case types.ATUALIZA_MENU_TOLLTIPS_PESQUISA:
      return { ...state, erro: "", tooltipPesquisa: payload };

    default:
      return state;
  }
};
