export const ATUALIZA_PAGINA_ATUAL = "studentcare/lista/atualiza-pagina-atual";
//export const ORDENAR_COLUNA = 'studentcare/lista/ordena-coluna';
export const ATUALIZA_QUANTIDADE_REGISTROS_POR_PAGINA =
  "studentcare/lista/atualiza-quantidade-registro-por-pagina";
export const ORDENAR_ASC_DESC = "studentcare/lista/ordenar-asc-desc";
export const ATUALIZA_LISTA = "studentcare/lista/atualiza-lista";
export const ATUALIZA_LISTA_EXCEL = "studentcare/lista/atualiza-lista-excel";
export const ATUALIZA_MODEL = "studentcare/lista/atualiza-model";
export const ATUALIZA_CARREGANDO = "studentcare/lista/atualiza-carregando";
export const ATUALIZA_CABECALHO = "studentcare/lista/atualiza-cabecalho";
export const ATUALIZA_CABECALHO_EXPORTAR =
  "studentcare/lista/atualiza-exportar";
export const ATUALIZA_PESQUISAR = "studentcare/lista/atualiza-pesquisar";
export const ATUALIZA_OPCOES_PADRAO =
  "studentcare/lista/atualiza-opcoes-padrao";
export const ATUALIZA_OPCOES_PERSONALIZADA =
  "studentcare/lista/atualiza-opcoes-personalizada";
export const ATUALIZA_ID_DE_FILTRO_POR_RELACIONAMENTO =
  "studentcare/lista/atualiza-id-de-filtro-por-relacionamento";
export const ATUALIZA_INFORMACOES_OBJECT_RELACIONAMENTO =
  "studentcare/lista/atualiza-informacoes-object-relacionamento";
export const ATUALIZA_IS_LIST_FILTERS_OPENED =
  "studentcare/lista/atualiza-is-list-filters-opened";
export const CLEAR_STATE = "studentcare/lista/CLEAR_STATE";
