import { IAxiosResponseCustom } from "../models/models";
import { IBaseDTO } from "../models/studentcare-api";
import { toastr } from "react-redux-toastr";
import axios from "./api";

const maxSize = 20;
// function kebabCaseToCamel(str) {
//   return str.replace(/(-\w)/g, matches => matches[1].toUpperCase());
// }

interface IIDProps {
  id?: number;
}

// export const getOne = <T extends IBaseDTO<number>>(resourceURL: string, body: IIDProps, config = {}): Promise<IAxiosResponseCustom<T>> =>
//   axios.get(`${resourceURL}/${body.id}`, config)

export default {
  //getAll: (resourceURL, config = {}) => axios.get(`${resourceURL}/all`, config),
  getOne: <T extends IBaseDTO<number>>(
    resourceURL: string,
    body: IIDProps,
    config = {},
  ): Promise<IAxiosResponseCustom<T>> =>
    axios.get(`${resourceURL}/${body.id}`, config),
  find: <T extends IBaseDTO<number>>(
    resourceURL: string,
    query = "",
    config = {},
  ): Promise<IAxiosResponseCustom<[T]>> =>
    axios.get(`${resourceURL}/query?${query}`, config),
  findVw: <I, T extends IBaseDTO<I>>(
    resourceURL: string,
    query = "",
    config = {},
  ): Promise<IAxiosResponseCustom<[T]>> =>
    axios.get(`${resourceURL}/query?${query}`, config),
  count: (
    resourceURL: string,
    query = "",
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.get(`${resourceURL}/count?${query}`, config),
  findPage: (
    resourceURL: string,
    query = "",
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.get(`${resourceURL}?${query}`, config),

  //getFullAll: (resourceURL, config = {}) => axios.get(`${resourceURL}/full/all`, config),
  getFullOne: <T extends IBaseDTO<number>>(
    resourceURL: string,
    body: IIDProps,
    config = {},
  ): Promise<IAxiosResponseCustom<T>> =>
    axios.get(`${resourceURL}/full/${body.id}`, config),
  findFull: <T extends IBaseDTO<number>>(
    resourceURL: string,
    query = "",
    config = {},
  ): Promise<IAxiosResponseCustom<[T]>> =>
    axios.get(`${resourceURL}/full/query?${query}`, config),
  findFullPage: (
    resourceURL: string,
    query = "",
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.get(`${resourceURL}/full?${query}`, config),

  create: (
    resourceURL: string,
    toCreate: IBaseDTO<number>,
    config = {},
  ): Promise<IAxiosResponseCustom> => axios.post(resourceURL, toCreate, config),
  createFull: (
    resourceURL: string,
    toCreate: IBaseDTO<number>,
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.post(`${resourceURL}/full`, toCreate, config),
  update: (
    resourceURL: string,
    toUpdate: IBaseDTO<number>,
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, config),
  patch: (
    resourceURL: string,
    body: IIDProps,
    toPatch: object,
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.patch(`${resourceURL}/${body.id}`, toPatch, config),
  delete: (
    resourceURL: string,
    body: IIDProps,
    config = {},
  ): Promise<IAxiosResponseCustom> =>
    axios.delete(`${resourceURL}/${body.id}`, config),

  findCombo: <T extends IBaseDTO<number>>(
    endPoint: string,
    label: string,
    inputValue: string,
    isAll: boolean,
  ): Promise<IAxiosResponseCustom<[T]>> => {
    return axios
      .get(
        `${endPoint}/clean?size=${maxSize}${
          inputValue ? "&" + label + "=" + inputValue : ""
        }`,
      )
      .then((res) => {
        const options = res.data.content.map((opcao: any) => ({
          value: { id: opcao.id },
          label: opcao[label],
        }));
        return isAll
          ? [
              {
                value: { id: 0 },
                label: "Todos(as)",
              },
              ...options,
            ]
          : options;
      })
      .catch((err) => {
        toastr.error(err.mensagem.data.error, err.mensagem.data.message);
        return [];
      });
  },
};
