// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
//import calenderReducer from "./calenderReducer";
import emailReducer from "./email/";
// import chatReducer from "./chatReducer";
import chatReducer from "./chat/";
//import contactsReducer from "./contacts/";
//import todoReducer from "./todo/";
import customizer from "./customizer/";

import listaReducer from "./lista/";
import formReducer from "./form/";
import menuReducer from "./menu/";

//import {reducer as notifications} from 'react-notification-system-redux';

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
  //calender: calenderReducer,
  emailApp: emailReducer,
  //contactApp: contactsReducer,
  //todoApp: todoReducer,
  toastr: toastrReducer, // <- Mounted at toastr.
  chatApp: chatReducer,
  customizer: customizer,

  /*INCLUÍDO*/
  stateForm: formReducer,
  stateLista: listaReducer,
  stateMenu: menuReducer,
  //stateNotifications:notifications
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
