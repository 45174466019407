// import external modules
import "font-awesome/css/font-awesome.min.css";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "./assets/cssnew/reset.scss";
import "./assets/cssnew/theme/light.scss";
import SpinnerComponent from "./components/spinner/spinner";
import "./index.scss";
import { store } from "./redux/storeConfig/store";
// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { AuthProvider } from "./utility/context/AuthContext";
//import LogRocket from 'logrocket';
//import setupLogRocketReact from 'logrocket-react';

// LogRocket.init('oieolh/studentcare');
// setupLogRocketReact(LogRocket);

const LazyApp = lazy(() => import("./app/app"));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<SpinnerComponent />}>
      <AuthProvider>
        <LazyApp />
      </AuthProvider>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  </Provider>,
  document.getElementById("root"),
);
registerServiceWorker();
