import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

const SpinnerComponent = () => {
  return (
    <div
      //className="sweet-loading"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 999999,
        position: "absolute",
        //marginLeft: "30px",
      }}
    >
      <PuffLoader
        className="clip-loader"
        sizeUnit={"px"}
        size={105}
        color={"#235FAD"}
        loading={true}
      />
    </div>
  );
};

export default SpinnerComponent;
