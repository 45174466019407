import * as types from "../../types/lista";
import object from "../../../utility/object";

const defaultState = {
  pagina: 1,
  totalPages: 0,
  totalRegistros: 0,
  isListFiltersOpened: false,
  listaCabecalho: [],
  listaCabecalhoExportar: [],
  opcoesPadrao: {
    adicionar: true,
    editar: true,
    excluir: true,
    detail: false,
  },
  opcoesOrdenacao: [
    { nome: "Ascendente", valor: "asc", checked: true },
    { nome: "Descendente", valor: "desc", checked: false },
  ],
  listaCorpo: [],
  listaCorpoExcel: [],
  model: {},
  loading: true,
};

const initialState = object.copySimpleObject(defaultState);

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case types.ATUALIZA_PAGINA_ATUAL:
      return { ...state, erro: "", pagina: payload };

    // case types.ORDENAR_COLUNA:
    //   return { ...state, erro: "", opcoesPesquisa: payload };

    case types.ORDENAR_ASC_DESC:
      return {
        ...state,
        erro: "",
        opcoesOrdenacao: defaultState.opcoesOrdenacao.map((opcao) => {
          opcao.checked = opcao.valor === payload;
          return opcao;
        }),
      };

    case types.ATUALIZA_LISTA:
      return payload.ok
        ? {
            ...state,
            erro: "",
            listaCorpo: payload.data.content,
            totalRegistros: payload.data.totalElements,
            totalPages: payload.data.totalPages,
            loading: false,
          }
        : {
            ...state,
            erro: "",
            listaCorpo: null,
            totalRegistros: 0,
            totalPages: 0,
            loading: false,
          };

    case types.ATUALIZA_LISTA_EXCEL:
      return { ...state, erro: "", listaCorpoExcel: payload };

    case types.ATUALIZA_MODEL:
      return {
        ...state,
        erro: "",
        model: payload,
        listaCorpo: [],
        totalRegistros: 0,
        totalPages: 0,
      };

    case types.ATUALIZA_CABECALHO:
      return { ...state, erro: "", listaCabecalho: payload };

    case types.ATUALIZA_CABECALHO_EXPORTAR:
      return { ...state, erro: "", listaCabecalhoExportar: payload };

    case types.ATUALIZA_OPCOES_PADRAO:
      return { ...state, erro: "", opcoesPadrao: payload };

    case types.ATUALIZA_OPCOES_PERSONALIZADA:
      return { ...state, erro: "", opcoesPersonalizada: payload };

    case types.ATUALIZA_CARREGANDO:
      return { ...state, erro: "", loading: payload };

    case types.ATUALIZA_IS_LIST_FILTERS_OPENED:
      return { ...state, erro: "", isListFiltersOpened: payload };

    // case types.ATUALIZA_INFORMACOES_OBJECT_RELACIONAMENTO:
    //   return { ...state, erro: "", informacoesObjectRelacionamento: payload };

    // case types.ATUALIZA_ID_DE_FILTRO_POR_RELACIONAMENTO:
    //   return { ...state, erro: "", idDeFiltroPorRelacionamento: payload };

    case types.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};
